import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import { FE_GET_NFT } from '../utils/api-urls-fe';
import fetcher from '../utils/fetch';

function useNft({ id, stats = false }) {
    if (!id) {
        return {
            nft: null,
            isLoading: false,
            isError: false,
        };
    }

    const { data, error } = useSWR(
        FE_GET_NFT(id, stats),
        (url) => fetcher(url),
        {
            refreshInterval: 30000,
        }
    );

    return {
        nft: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export { useNft };

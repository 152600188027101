import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Image from 'next/image';
import CustomModal from '../custom-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookMessengerShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    TwitterIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    WhatsappIcon,
    TelegramIcon,
} from 'react-share';
import { useSnackbar } from 'react-simple-snackbar';
import { snackBarOptions } from '../../../utils/snackbar-options';
import {
    LOGO_IMAGE_PATH,
    MODAL_LOGO_IMAGE_HEIGHT,
    MODAL_LOGO_IMAGE_WIDTH,
} from '../../../utils/constants';
import { amplitudeUtil } from '../../../utils/amplitude/client';
import modalStyles from './shareModal.module.css';

export const ShareModal = ({ handleModalClose, isModalOpen, nftId }) => {
    const [copy, setCopy] = useState({ value: '', copied: false });
    const [openSnackbar, closeSnackbar] = useSnackbar(snackBarOptions);
    const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;
    const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID;

    useEffect(() => {
        setCopy({
            value: `${APP_BASE_URL}/nft/${nftId}`,
            copied: false,
        });
    }, [nftId]);

    const setModalClose = (e) => {
        e.stopPropagation();
        amplitudeUtil.log('NFT Share Link [Copied]', {
            id: nftId,
        });
        handleModalClose();
        openSnackbar('URL Copied!', 3000);
    };

    if (isModalOpen) {
        return ReactDOM.createPortal(
            <CustomModal
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
            >
                <div
                    style={{ background: 'white', borderRadius: '8px' }}
                    className="d-flex justify-content-center align-items-center mb-2 px-2"
                >
                    <div>
                        <div className="text-center">
                            <Image
                                src={LOGO_IMAGE_PATH}
                                height={MODAL_LOGO_IMAGE_HEIGHT}
                                width={MODAL_LOGO_IMAGE_WIDTH}
                            />

                            <div>
                                <h5 className="font-32 color-black m-0">
                                    Share on Social Media
                                </h5>
                            </div>
                        </div>
                        <div className="my-4 d-flex align-items-center">
                            <div
                                onClick={setModalClose}
                                className="d-flex w-100 justify-content-center cursor-pointer"
                            >
                                <CopyToClipboard text={copy.value}>
                                    <div
                                        className={`d-flex  align-items-center ${modalStyles.inputDiv}`}
                                    >
                                        <input
                                            disabled
                                            value={copy.value}
                                            onChange={(e) =>
                                                setCopy({
                                                    value: e.target.value,
                                                    copied: false,
                                                })
                                            }
                                            className="br-8 w-100 border-none"
                                        />
                                        <div
                                            className={`px-2 ${modalStyles.inputPicDiv}`}
                                        >
                                            <img src="/assets/icon/copy.svg" />
                                        </div>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="d-flex px-3 justify-content-center">
                            <div className="px-1">
                                <FacebookShareButton
                                    url={`${copy.value}`}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={46} round={true} />
                                </FacebookShareButton>
                            </div>
                            <div className="px-1">
                                <TwitterShareButton url={`${copy.value}`}>
                                    <TwitterIcon size={46} round={true} />
                                </TwitterShareButton>
                            </div>
                            <div className="px-1">
                                <WhatsappShareButton
                                    separator=":: "
                                    url={`${copy.value}`}
                                >
                                    <WhatsappIcon size={46} round={true} />
                                </WhatsappShareButton>
                            </div>
                            <div className="px-1">
                                <TelegramShareButton url={`${copy.value}`}>
                                    <TelegramIcon size={46} round={true} />
                                </TelegramShareButton>
                            </div>
                            {/* 
                                <FacebookMessengerShareButton
                                    url={`${copy.value}`}
                                    appId={FB_APP_ID}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookMessengerIcon
                                        size={46}
                                        round={true}
                                    />
                                </FacebookMessengerShareButton> */}
                        </div>
                    </div>
                </div>
            </CustomModal>,
            document.getElementById('modal-root')
        );
    }

    return null;
};

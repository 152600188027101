import React, { useState } from 'react';
import { ShareModal } from '../../modals/share-modal';
import {
    Container,
    CategoryButton,
    ShareButton,
    ImageContainer,
} from './styles';
import { amplitudeUtil } from '../../../utils/amplitude/client';

interface INftMetaProps {
    id: string;
    title?: string;
    category: any;
    image?: string;
    vertical?: boolean;
    small?: boolean;
    isMaster?: boolean;
}

const NftMeta = (props: INftMetaProps) => {
    const { id, category, image, vertical, small, title, isMaster } = props;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [nftShareId, setNftShareId] = useState<string>('');

    const handleModalOpen = (e) => {
        amplitudeUtil.log('Share Button [Clicked]', {
            id: id,
            title: title,
            category: category,
        });
        setIsModalOpen(true);
        setNftShareId(e.currentTarget.dataset.id);
        e.stopPropagation();
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const mt5 = !vertical && 'mt-4';
    const mb5 = vertical && 'mb-5';
    const mb3 = vertical && 'mb-3';

    const shareImage = {
        height: small ? '24px' : '',
        width: small ? '24px' : '',
    };

    const categoryImage = {
        height: small ? '16px' : '',
        width: small ? '16px' : '',
    };

    return (
        <>
            <Container
                className={mt5}
                isVertical={vertical}
                style={{
                    marginBottom: '30px',
                }}
            >
                {image && (
                    <ImageContainer className={mb5}>
                        <img
                            src={image}
                            alt={'nft-img'}
                            width="297px"
                            height="297px"
                            style={{
                                padding: '18.56px',
                                borderRadius: '42px',
                            }}
                        />
                    </ImageContainer>
                )}

                <CategoryButton
                    className={mb3}
                    small={small}
                    isMaster={isMaster}
                >
                    <img
                        src={
                            isMaster
                                ? '/assets/icon/star-icon.svg'
                                : '/assets/icon/special-btn-icon.svg'
                        }
                        alt={`${category?.title} image`}
                        height={categoryImage.height}
                        width={categoryImage.width}
                    />
                    <span className="ms-2">{category?.title}</span>
                </CategoryButton>

                <ShareButton data-id={`${id}`} onClick={handleModalOpen}>
                    <img
                        src="/assets/icon/nft-share-icon.svg"
                        alt={'share-icon'}
                        height={shareImage.height}
                        width={shareImage.width}
                    />
                </ShareButton>
            </Container>

            <ShareModal
                nftId={nftShareId}
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
            />
        </>
    );
};

export default NftMeta;

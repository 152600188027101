import styled from 'styled-components';
import { size } from '../../../../utils/device';

export const PrimaryPrice = styled.h6`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #fefefe;
    margin: 0;

    @media (max-width: ${size.mobileM}) {
        font-size: 16px;
    }
`;

import React, { useEffect, useState } from 'react';
import { Container } from '../../nft-price-container-common/styles';
import {
    AwaitingPurchaseContainer,
    OpenForPurchaseContainer,
    ReservedContainer,
} from '../index';
import { snackBarOptions } from '../../../utils/snackbar-options';
import { useSnackbar } from 'react-simple-snackbar';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { LoginModal } from '../../modals/login-modal';
import { AppLoader } from '../../loader';
import { axiosClient } from '../../../utils/axios';
import { IReservation } from '../../../types/reservation';
import { pusherUtil } from '../../../utils/pusher';
import {
    EdtionNftReservationUtil,
    ReservationAPI,
} from '../../../utils/reservation';
import ThreeDotsLoader from '../../loader/three-dots-loader';
import CancelConfirmationModal from '../../modals/confirm-modal';
import {
    FE_GET_NFT,
    FE_POST_BUY_EDITION_NFT_WITH_CRYPTO,
    FE_POST_BUY_EDITION_NFT_WITH_FIAT,
    FE_POST_BUY_EDITION_NFT_WITH_SENDWYRE,
} from '../../../utils/api-urls-fe';
import { amplitudeUtil } from '../../../utils/amplitude/client';
import BuyContainer from '../buy-container';
import { useSWRConfig } from 'swr';
import {
    NftInfoBarCancelationTimer,
    NftInfoBarQueued,
    NftInfoBarOwnedEditions,
} from '../../nft-info-bars';
// import { useAppDispatch } from '../../../redux/store';

const OnSaleContainerEditionDetailPage = ({
    priceUsd,
    priceBtc,
    setRootContainerStatus,
    sticky,
    setShowConfetti,
    bankPayment,
    creator,
}) => {
    const { data: session, status: sessionStatus } = useSession();
    const [pusherConnected, setPusherConnected] = useState(false);
    const [nftChannel, setNftChannel] = useState(undefined);
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [callbackPath, setCallbackPath] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [reservedState, setReservedState] = useState<string>(null);
    const [reservation, setReservation] = useState<IReservation>(null);
    const [openSnackbar] = useSnackbar(snackBarOptions);
    const router = useRouter();
    const nftId = router.query.id as string;
    const [updatedUsdPrice, setUpdatedUsdPrice] = useState(priceUsd);
    const [updatedSecondaryPrice, setUpdatedSecondaryPrice] =
        useState(priceBtc);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const { mutate } = useSWRConfig();
    // const dispatch = useAppDispatch();

    const NO_DECAY_STATES = [
        'awaiting',
        'processing',
        'confirmed',
        'completed',
    ];
    const NFT_STATES = {
        ONSALE: 'onsale',
        SOLD: 'sold',
    };

    const STATES = {
        SELECTED: 'selected',
        QUEUED: 'queued',
        DECLINED: 'declined',
        CANCELLED: 'canceled',
        PENDING: 'pending',
        AWAITING: 'awaiting',
        FAILED: 'failed',
        PROCESSING: 'processing',
        COMPLETED: 'completed',
        CONFIRMED: 'confirmed',
    };

    const CHECK_RESERVATION_STATUS = async (reservationId) => {
        const apiRes = await ReservationAPI(
            `/api/reservation/${reservationId}`
        );
        setButtonLoading(false);

        if (apiRes.status && apiRes.data) {
            const data = apiRes.data;
            const orderStatus = data.status;
            const reservationId = data._id;
            const lastOrderUrl = data.lastOrderUrl;
            const orderService = data.orderService;
            const orderPrice = data.orderPrice;

            setReservedState(orderStatus);
            setReservation((prevData) => ({
                ...prevData,
                id: reservationId,
                status: orderStatus,
                lastOrderUrl: lastOrderUrl,
                orderService: orderService,
                orderPrice: orderPrice,
            }));

            if (orderStatus === STATES.COMPLETED) {
                setShowConfetti(true);
            }
        }
    };

    const GET_RESERVATION = () => {
        const callReservationStatus = async () => {
            const userId = session.userId;
            const res = await EdtionNftReservationUtil.GET_RESERVATION_STATUS(
                userId,
                nftId
            );
            if (res && res.status) {
                const reservation = res.data;

                if (reservation) {
                    const status: any = reservation.status;
                    const price: number = reservation.orderPrice;
                    setReservedState(reservation.status);
                    setReservation((prevData) => ({
                        ...prevData,
                        id: reservation.id,
                        status: status,
                        lastOrderUrl: reservation.lastOrderUrl ?? '',
                        orderService: reservation.orderService,
                        expiryTime: reservation.expiryTime,
                        orderPrice: price,
                    }));

                    const lockedPrimaryPrice = 'USD ' + price;

                    if (NO_DECAY_STATES.includes(status)) {
                        setUpdatedUsdPrice(lockedPrimaryPrice);
                        const apiResp = await axiosClient.get('/price-eth');
                        const response = await apiResp.data;
                        const rate = response.data;
                        if (response.status) {
                            const lockedSecPrice = (
                                Number(price) * Number(rate)
                            ).toFixed(5);
                            setUpdatedSecondaryPrice(lockedSecPrice);
                        }
                    }
                }
            } else {
                openSnackbar('Something went wrong, please try again', 3000);
            }
            setButtonLoading(false);
        };

        setTimeout(() => {
            if (!reservation) {
                callReservationStatus();
            }
        }, 2000);
    };

    useEffect(() => {
        if (reservation) {
            if (!NO_DECAY_STATES.includes(reservation.status)) {
                setUpdatedUsdPrice(priceUsd);
            }
        } else {
            setUpdatedUsdPrice(priceUsd);
        }
    }, [priceUsd]);

    useEffect(() => {
        if (sessionStatus == 'loading') {
            setButtonLoading(true);
        } else if (sessionStatus === 'authenticated' && session) {
            setButtonLoading(true);

            if (!pusherConnected) {
                const channel = pusherUtil.subscribe(nftId);
                setNftChannel(channel);
                setPusherConnected(true);
                // console.log('pusher subscribed');
            }

            if (router.query.continue === 'reserve') {
                router
                    .replace(`/nft/${nftId}`, undefined, { shallow: true })
                    .finally(() => {
                        handleOnClickReserve();
                    });
            } else if (
                router.query.s === 'coinbase' &&
                router.query.a === 'cancel'
            ) {
                router.replace(`/nft/${nftId}`).finally(() => {
                    openSnackbar('Your reservation failed!', 3000);
                    setReservedState(STATES.FAILED);
                    GET_RESERVATION();
                });
            } else if (
                router.query.s === 'coinbase' &&
                router.query.a === 'success'
            ) {
                router.replace(`/nft/${nftId}`).finally(() => {
                    openSnackbar('Your payment is under processing!', 3000);
                    setReservedState(STATES.PROCESSING);
                    GET_RESERVATION();
                });
            } else if (
                router.query.s === 'sendwyre' &&
                router.query.a === 'failed'
            ) {
                router.replace(`/nft/${nftId}`).finally(() => {
                    openSnackbar('Your reservation failed!', 3000);
                    setReservedState(STATES.FAILED);
                    GET_RESERVATION();
                });
            } else if (
                router.query.s === 'sendwyre' &&
                router.query.a === 'success'
            ) {
                const reservationId = router.query.r;
                router.replace(`/nft/${nftId}`).finally(() => {
                    setButtonLoading(true);
                    CHECK_RESERVATION_STATUS(reservationId);
                });
            } else {
                GET_RESERVATION();
            }
        } else if (sessionStatus === 'unauthenticated') {
            setButtonLoading(false);
        }
        return () => {
            if (pusherConnected) {
                pusherUtil.unsubscribe(nftId);
                pusherUtil.disconnect();
                setPusherConnected(false);
                // console.log('pusher unsubscribed');
            }
        };
    }, [sessionStatus]);

    useEffect(() => {
        if (nftChannel) {
            nftChannel.bind('price-update', ({ message }) => {
                if (message) {
                    openSnackbar('Price updated!', 3000);
                    const updatedPrice = 'USD ' + message;
                    setUpdatedUsdPrice(updatedPrice);
                }
            });

            nftChannel.bind('broadcast', ({ message }) => {
                if (message === STATES.DECLINED) {
                    if (reservedState === STATES.QUEUED)
                        openSnackbar('Your reservation was declined!', 3000);
                    if (
                        ![
                            STATES.COMPLETED,
                            STATES.CONFIRMED,
                            STATES.PROCESSING,
                        ].includes(reservedState)
                    )
                        setRootContainerStatus(NFT_STATES.SOLD);
                }
            });

            if (reservation && reservation.id) {
                nftChannel.bind(reservation.id, ({ message, edition }) => {
                    if (message === STATES.SELECTED) {
                        setReservedState(STATES.SELECTED);
                    } else if (message === STATES.CANCELLED) {
                        amplitudeUtil.log(`Payment Canceled`, {
                            nftId,
                            isMasterEdition: true,
                        });
                        openSnackbar('Your reservation was cancelled!', 3000);
                        setReservedState(STATES.CANCELLED);
                    } else if (message === STATES.PROCESSING) {
                        amplitudeUtil.log(`Payment Processing`, {
                            nftId,
                            isMasterEdition: true,
                        });
                        openSnackbar('Your payment is processing!', 3000);
                        setReservedState(STATES.PROCESSING);
                    } else if (message === STATES.FAILED) {
                        amplitudeUtil.log(`Payment Failed`, {
                            nftId,
                            isMasterEdition: true,
                        });
                        openSnackbar('Your payment failed!', 3000);
                        setReservedState(STATES.FAILED);
                    } else if (message === STATES.CONFIRMED) {
                        amplitudeUtil.log(`Payment Confirmed`, {
                            nftId,
                            isMasterEdition: true,
                        });
                        openSnackbar('Your payment is confirmed!', 3000);
                        setReservedState(STATES.CONFIRMED);
                        GET_RESERVATION();
                    } else if (message === STATES.COMPLETED) {
                        amplitudeUtil.log(`Nft Minted [Success]`, {
                            nftId,
                            isMasterEdition: true,
                        });
                        // if (edition) {
                        //     setNftEditionRedirectionId(edition._id);
                        // }
                        setReservedState(STATES.COMPLETED);
                        setShowConfetti(true);
                    }
                });
            }
        }

        (async () => {
            if (reservation && reservation.status) {
                const rStatus: any = reservation.status;
                if ([NO_DECAY_STATES].includes(rStatus)) {
                    const status: any = reservation.status;
                    const price: number = reservation.orderPrice;

                    const lockedPrimaryPrice = 'USD ' + price;
                    if ([NO_DECAY_STATES].includes(status)) {
                        setUpdatedUsdPrice(lockedPrimaryPrice);

                        const apiResp = await axiosClient.get('/price-eth');
                        const response = await apiResp.data;
                        const rate = response.data;
                        if (response.status) {
                            const lockedSecPrice = (
                                Number(price) * Number(rate)
                            ).toFixed(5);
                            setUpdatedSecondaryPrice(lockedSecPrice);
                        }
                    }
                }
            }
        })();
    }, [reservation, reservedState]);

    // const handleRedirectToEditionNft = () => {
    //     // amplitudeUtil.log(`View Nft Button [Clicked]`, {
    //     //     nftId: nftEditionRedirectionId ? nftEditionRedirectionId : nftId,
    //     //     isMasterEdition: true,
    //     // });
    //     // if (nftEditionRedirectionId)
    //     //     return router.push(`/nft/${nftEditionRedirectionId}`);

    //     mutate(FE_GET_NFT(nftId, true));
    //     mutate(FE_GET_OWNED_EDITIONS(nftId));
    //     router.push(`/${session.username}`);
    // };

    const handleOnClickReserve = async () => {
        amplitudeUtil.log(`Buy Nft Button [Clicked]`, {
            nftId,
            isMasterEdition: true,
        });
        if (sessionStatus === 'authenticated' && session) {
            const userId = session.userId;
            const res = await EdtionNftReservationUtil.RESERVE_NFT(
                setButtonLoading,
                userId,
                nftId
            );
            if (res && res.status) {
                mutate(FE_GET_NFT(nftId, true));
                setReservedState(res.reservationStatus);
                setReservation((prevData) => ({
                    ...prevData,
                    id: res.reservationId,
                    status: res.reservationStatus,
                    lastOrderUrl: res?.lastOrderUrl ?? '',
                    orderService: res?.orderService,
                    orderPrice: res?.orderPrice,
                }));

                amplitudeUtil.log(`Queued [Success]`, {
                    nftId,
                    isMasterEdition: true,
                });
            } else {
                amplitudeUtil.log(`Queued [Failed]`, {
                    nftId,
                    isMasterEdition: true,
                });
                openSnackbar(
                    'Something went wrong, please reload the page.',
                    3000
                );
            }
            setButtonLoading(false);
        } else {
            setCallbackPath(`/nft/${nftId}?continue=reserve`);
            setShowLoginModal(true);
        }
    };
    // const handleOnClickBank = async () => {
    //     setButtonLoading(true);

    //     amplitudeUtil.log(`Pay via Bank [Clicked]`, {
    //         nftId,
    //     });
    //     const userId = session.userId;
    //     try {
    //         const res = await axiosClient.post(
    //             FE_POST_BUY_EDITION_NFT_VIA_BANK,
    //             {
    //                 userId,
    //                 nftId,
    //                 reservationId: reservation.id,
    //             }
    //         );
    //         setButtonLoading(false);
    //         if (!res.data) {
    //             openSnackbar('Reservation expired!', 3000);
    //             setReservedState(STATES.CANCELLED);
    //         } else if (res.data.status) {
    //             const reservationData = res.data.data.data;
    //             mutate(
    //                 FE_GET_RESERVATION_STATUS(
    //                     reservationData.reservationId,
    //                     true
    //                 )
    //             );
    //             setReservedState(STATES.AWAITING);
    //             setReservation((prevData) => ({
    //                 ...prevData,
    //                 id: reservationData.reservationId,
    //                 status: STATES.AWAITING,
    //                 orderService: 'bank-transfer',
    //             }));

    //             // dispatch(
    //             //     timerStarted(
    //             //         `We have sent you ${creator.name}'s account # from sales@superfandom.io. Please check your email.`
    //             //     )
    //             // );
    //             // openSnackbar(
    //             //     `We have sent you ${creator.name}'s account # to ${session.email}. Please check your email.`,
    //             //     7000
    //             // );
    //         } else {
    //             openSnackbar('Something went wrong, please try again', 3000);
    //         }
    //     } catch (error) {
    //         setButtonLoading(false);
    //         openSnackbar(error.message, 3000);
    //     }
    // };

    const handleOnClickBuyWithCrypto = async () => {
        setButtonLoading(true);
        amplitudeUtil.log(`Pay With Crypto [Clicked]`, {
            nftId,
            isMasterEdition: true,
        });
        const userId = session.userId;

        try {
            const apiRes = await axiosClient.post(
                FE_POST_BUY_EDITION_NFT_WITH_CRYPTO,
                {
                    userId,
                    nftId,
                    reservationId: reservation.id,
                }
            );
            setButtonLoading(false);

            const response = apiRes.data;
            if (!response.status)
                return openSnackbar(
                    'Something went wrong, please try again',
                    3000
                );

            const { orderUrl, reservationId, orderService, orderPrice } =
                response.data;
            // setReservedState(res.data.data.status);
            setReservation((prevData) => ({
                ...prevData,
                id: reservationId,
                lastOrderUrl: orderUrl,
                orderService: orderService,
                orderPrice: orderPrice,
            }));

            if (orderUrl != '') {
                setShowLoader(true);
                router.push(orderUrl);
            } else {
                openSnackbar('Failed to get purchase order.', 3000);
            }
        } catch (error) {
            setButtonLoading(false);
            openSnackbar(error.message, 3000);
        }
    };

    const handleOnClickBuyWithFiat = async (country) => {
        setButtonLoading(true);
        amplitudeUtil.log(`Pay With Fiat [Clicked]`, {
            nftId,
            isMasterEdition: true,
        });
        const userId = session.userId;

        try {
            const apiRes = await axiosClient.post(
                FE_POST_BUY_EDITION_NFT_WITH_FIAT,
                {
                    userId,
                    nftId,
                    reservationId: reservation.id,
                    country,
                }
            );

            setButtonLoading(false);

            if (apiRes?.status) {
                // console.log(
                //     'Response [handleOnClickBuyWithFiat - NftEdition]',
                //     apiRes
                // );

                const response = apiRes.data;

                if (response.status && response.data) {
                    setShowLoader(true);

                    const redirectLink = response.data.orderUrl;

                    // console.log('redirectLink :>> ', redirectLink);
                    router.replace(redirectLink);
                } else {
                    openSnackbar(response.data.statusMsg, 3000);
                }
            }
        } catch (error) {
            setButtonLoading(false);
            // console.log('Error [handleOnClickBuyWithFiat]', error);
        }
    };

    const handleOnClickBuyWithSendwyre = async (country) => {
        setButtonLoading(true);
        amplitudeUtil.log(`Pay With Sendwyre [Clicked]`, {
            nftId,
            isMasterEdition: true,
        });

        try {
            const apiRes = await axiosClient.post(
                FE_POST_BUY_EDITION_NFT_WITH_SENDWYRE,
                {
                    nftId,
                    reservationId: reservation.id,
                    country,
                }
            );

            setButtonLoading(false);

            const response = apiRes.data;
            if (!response.status) {
                openSnackbar(response.statusMsg, 3000);
            } else {
                setShowLoader(true);
                const redirectLink = response.data.orderUrl;
                console.log('redirectLink :>> ', redirectLink);
                router.replace(redirectLink);
            }
        } catch (error) {
            setButtonLoading(false);
        }
    };

    const handleOnClickCancel = async () => {
        // show confirmation pop up
        setShowConfirmation(true);
    };

    const onCancelClose = async () => {
        // hide confirmation pop up
        setShowConfirmation(false);
    };

    const onCancelConfirm = async () => {
        amplitudeUtil.log(`Cancel Reservation [Clicked]`, {
            nftId,
        });
        // call cancel api
        if (sessionStatus === 'authenticated' && session) {
            setShowConfirmation(false);
            setButtonLoading(true);
            const userId = session.userId;
            const res = await EdtionNftReservationUtil.CANCEL_RESERVATION(
                userId,
                nftId,
                reservation.id
            );
            if (res && res.status) {
                amplitudeUtil.log(`Cancel Reservation [Success]`, {
                    nftId,
                    isMasterEdition: true,
                });
                setReservedState(null);
                setReservation(null);
                openSnackbar('Successfully canceled reservation', 3000);
            } else {
                amplitudeUtil.log(`Cancel Reservation [Success]`, {
                    nftId,
                    isMasterEdition: true,
                });
                openSnackbar('Something went wrong, please try again', 3000);
            }
            setButtonLoading(false);
        } else {
            setCallbackPath(`/nft/${nftId}?continue=cancel-reservation`);
            setShowLoginModal(true);
        }
    };

    return (
        <>
            {buttonLoading || sessionStatus == 'loading' ? (
                <Container
                    status={NFT_STATES.ONSALE}
                    sticky={sticky}
                    centered={true}
                >
                    <ThreeDotsLoader />
                </Container>
            ) : (
                <>
                    {(reservedState === null ||
                        reservedState === STATES.COMPLETED ||
                        reservedState === STATES.CANCELLED ||
                        reservedState === STATES.DECLINED ||
                        reservedState === STATES.FAILED) && (
                        <>
                            <NftInfoBarOwnedEditions />
                            <BuyContainer
                                status={NFT_STATES.ONSALE}
                                sticky={sticky}
                                updatedUsdPrice={updatedUsdPrice}
                                priceBtc={updatedSecondaryPrice}
                                handleOnClick={handleOnClickReserve}
                            />
                        </>
                    )}
                    {reservedState === STATES.SELECTED && (
                        <>
                            <NftInfoBarCancelationTimer />
                            <OpenForPurchaseContainer
                                status={STATES.SELECTED}
                                priceBtc={updatedSecondaryPrice}
                                priceUsd={updatedUsdPrice}
                                handleOnClickCrypto={handleOnClickBuyWithCrypto}
                                handleOnClickFiat={handleOnClickBuyWithFiat}
                                handleOnClickSendwyre={
                                    handleOnClickBuyWithSendwyre
                                }
                                sticky={sticky}
                                bankPayment={bankPayment}
                                creator={creator}
                                setReservedState={setReservedState}
                                setReservation={setReservation}
                                reservation={reservation}
                            />

                            {showLoader && (
                                <AppLoader text="Redirecting to payment gateway" />
                            )}
                        </>
                    )}
                    {[STATES.PENDING, STATES.AWAITING].includes(
                        reservedState
                    ) && (
                        <>
                            <NftInfoBarCancelationTimer />
                            <AwaitingPurchaseContainer
                                status={STATES.SELECTED}
                                priceBtc={updatedSecondaryPrice}
                                priceUsd={updatedUsdPrice}
                                handleOnClickCrypto={() =>
                                    EdtionNftReservationUtil.GO_TO_PAYMENT_GATEWAY(
                                        setButtonLoading,
                                        setShowLoader,
                                        router,
                                        reservation?.lastOrderUrl,
                                        reservation?.orderService,
                                        reservation?.id,
                                        session?.userId,
                                        nftId,
                                        setReservedState
                                    )
                                }
                                buttonLoading={!reservation?.lastOrderUrl}
                                sticky={sticky}
                                orderService={reservation?.orderService}
                            />

                            {showLoader && (
                                <AppLoader text="Redirecting to payment gateway" />
                            )}
                        </>
                    )}
                    {reservedState === STATES.QUEUED && (
                        <>
                            <NftInfoBarQueued />
                            <ReservedContainer
                                status={STATES.QUEUED}
                                priceBtc={updatedSecondaryPrice}
                                priceUsd={updatedUsdPrice}
                                message={'Queued'}
                                sticky={sticky}
                                showCancel={true}
                                onClickCancel={handleOnClickCancel}
                            />
                        </>
                    )}
                    {reservedState === STATES.PROCESSING && (
                        <>
                            <NftInfoBarOwnedEditions />
                            <ReservedContainer
                                status={STATES.QUEUED}
                                priceBtc={updatedSecondaryPrice}
                                priceUsd={updatedUsdPrice}
                                message={'Processing Payment...'}
                                sticky={sticky}
                            />
                        </>
                    )}
                    {reservedState === STATES.CONFIRMED && (
                        <>
                            <NftInfoBarOwnedEditions />
                            <ReservedContainer
                                status={STATES.QUEUED}
                                priceBtc={updatedSecondaryPrice}
                                priceUsd={updatedUsdPrice}
                                message={'Collecting NFT...'}
                                sticky={sticky}
                            />
                        </>
                    )}

                    {/* {reservedState === STATES.COMPLETED && (
                        <ReservedContainer
                            status={STATES.QUEUED}
                            priceBtc={priceBtc}
                            priceUsd={updatedUsdPrice}
                            message={'You got it!'}
                            sticky={sticky}
                        />
                    )} */}
                </>
            )}
            {/* Confetti Moved to Root ../nft-price-container-detail-page */}
            <LoginModal
                show={showLoginModal}
                setShow={setShowLoginModal}
                callbackPath={callbackPath}
            />
            <CancelConfirmationModal
                showModal={showConfirmation}
                onClickClose={onCancelClose}
                onClickConfirm={onCancelConfirm}
            />
            {showLoader && <AppLoader text="Redirecting to payment gateway" />}
        </>
    );
};

export default OnSaleContainerEditionDetailPage;

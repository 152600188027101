import CustomModalLayout from './index.layout';

const CustomModal = ({ isModalOpen, handleModalClose, children }) => {
    return (
        <CustomModalLayout
            isModalOpen={isModalOpen}
            handleModalClose={handleModalClose}
        >
            {children}
        </CustomModalLayout>
    );
};

export default CustomModal;

import axios from 'axios';

const APP_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;

export const axiosClient = axios.create({
    baseURL: APP_URL + '/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

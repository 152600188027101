import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import useReservation from '../../../hooks/useReservation';
import { TimeUtil } from '../../../utils/time-converter';
import { NftInfoBarContainer } from '../../nft-price-container-common/styles';

export const NftInfoBarCancelationTimer = () => {
    const [reservationData, setReservationData] = useState(null);
    const [showCompleted, setShowCompleted] = useState(false);

    const router = useRouter();
    const nftId = router.query.id as string;
    const { reservation } = useReservation(nftId, true);

    useEffect(() => {
        if (reservation && reservation.data) {
            const { expiresAt } = reservation.data;
            setReservationData({
                expiresAt: TimeUtil.toLocalTime(expiresAt),
            });
        }

        return () => setShowCompleted(false);
    }, [reservation]);

    const countdownRenderer = ({ formatted }) => {
        return (
            <>
                {!!showCompleted && <span>Canceling order...</span>}
                {!showCompleted && (
                    <>
                        <span>Time to cancelation: </span>
                        <span>
                            {formatted.minutes}:{formatted.seconds}
                        </span>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {reservationData && reservationData.expiresAt != null && (
                <NftInfoBarContainer>
                    <p>
                        <Countdown
                            key={reservationData.expiresAt}
                            date={reservationData.expiresAt}
                            renderer={countdownRenderer}
                            onComplete={() => setShowCompleted(true)}
                        />
                    </p>
                </NftInfoBarContainer>
            )}
        </>
    );
};

import React from 'react';
import Image from 'next/image';

export const CreatorInfo = ({ creator }) => {
    const { name, userAvatar } = creator;

    return (
        <div className="p-1">
            <div className="d-flex justify-content-center align-items-center">
                <div
                    className="mx-1 br-24"
                    style={{
                        width: '35px',
                        height: '35px',
                        overflow: 'hidden',
                    }}
                >
                    <Image
                        className="object-fit"
                        src={userAvatar}
                        width="35px"
                        height="35px"
                    />
                </div>
                <div className="mx-1">
                    <p
                        style={{
                            maxWidth: '100px',
                            height: '20px',
                            overflow: 'hidden',
                            wordBreak: 'break-word',
                        }}
                        className="m-0 font-16 color-black"
                    >
                        {name}
                    </p>
                </div>
            </div>
        </div>
    );
};

export * from './nft';
export * from './nft-edition';

export const ReservationAPI = async (
    url,
    method: string = 'GET',
    data?: any
) => {
    const body =
        (method === 'POST' && data && JSON.stringify(data)) || undefined;
    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });
    const result = await response.json();

    return result;
};

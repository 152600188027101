import { IReservation } from '../../../types/reservation';
import { axiosClient } from '../../axios';

const RESERVE_NFT = async (setButtonLoading, userId, nftId) => {
    setButtonLoading(true);
    try {
        const apiResp = await axiosClient.post(
            'reservation/nft-edition/reserve',
            {
                userId,
                nftId,
            }
        );

        setButtonLoading(false);

        const response = apiResp.data;
        if (!response.status) return { status: false };

        const reservation = response.data.reservation;
        return {
            status: response.status,
            reservationId: reservation._id,
            reservationStatus: reservation.status,
            lastOrderUrl: reservation.lastOrderUrl,
            orderService: reservation.orderService,
            orderPrice: reservation.orderPrice,
        };
    } catch (error) {
        setButtonLoading(false);
        console.error('ERROR [RESERVE_NFT]', error);
    }
};

const GET_RESERVATION_STATUS = async (
    userId,
    nftId
): Promise<{
    status: boolean;
    statusMsg: string;
    data: IReservation;
}> => {
    try {
        const axiosRes = await axiosClient.post(
            'reservation/nft-edition/status',
            {
                userId,
                nftId,
            }
        );

        if (axiosRes.status != 200)
            return {
                status: false,
                statusMsg: 'Api request failed',
                data: undefined,
            };

        const apiResponse = axiosRes.data;

        if (!apiResponse.status)
            return {
                status: false,
                statusMsg: 'Api response not success',
                data: undefined,
            };

        if (!apiResponse.data)
            return {
                status: true,
                statusMsg: 'No reservation data found.',
                data: undefined,
            };

        const reservation = apiResponse.data;
        return {
            status: true,
            statusMsg: 'Reservation data found.',
            data: {
                status: reservation.status,
                id: reservation._id,
                lastOrderUrl: reservation.lastOrderUrl,
                orderService: reservation.orderService,
                expiryTime: reservation.expiryTime,
                orderPrice: reservation.orderPrice,
            },
        };
    } catch (error) {
        console.error('ERROR [GET_RESERVATION_STATUS]', error);
        return {
            status: false,
            statusMsg: 'Failed to fetch reservation.',
            data: undefined,
        };
    }
};

const GO_TO_PAYMENT_GATEWAY = async (
    setButtonLoading,
    setShowLoader,
    router,
    lastOrderUrl,
    orderService,
    reservationId,
    userId,
    nftId,
    setReservationState
) => {
    console.log('payment gateway func called');
    if (orderService === 'bank-transfer') {
        setButtonLoading(true);

        const cancelRes = await CANCEL_RESERVATION(
            userId,
            nftId,
            reservationId,
            true
        );
        if (!cancelRes.status) {
            setButtonLoading(false);
            return {
                status: false,
                statusMsg: 'Failed to cancel reservation',
            };
        }
        setReservationState('canceled');
        setButtonLoading(false);
    } else {
        setButtonLoading(true);
        setShowLoader(true);
        router.push(lastOrderUrl);
    }
};

const CANCEL_RESERVATION = async (
    userId: string,
    nftId: string,
    reservationId: string,
    bankTransfer = false
): Promise<{ status: boolean; statusMsg: string }> => {
    try {
        let URL = 'reservation/nft-edition/cancel';
        if (bankTransfer) {
            URL = 'reservation/nft/cancel-bank-transfer';
        }
        const axiosRes = await axiosClient.post(URL, {
            userId,
            nftId,
            reservationId,
        });

        if (axiosRes.status != 200)
            return {
                status: false,
                statusMsg: 'Api request failed',
            };

        const apiResponse = axiosRes.data;

        if (!apiResponse.status)
            return {
                status: false,
                statusMsg: 'Api response not success',
            };

        return {
            status: true,
            statusMsg: 'Reservation canceled.',
        };
    } catch (error) {
        console.error('ERROR [RESERVE_NFT]', error);
        return {
            status: false,
            statusMsg: 'Failed to cancel reservation.',
        };
    }
};

export const EdtionNftReservationUtil = {
    RESERVE_NFT,
    GET_RESERVATION_STATUS,
    GO_TO_PAYMENT_GATEWAY,
    CANCEL_RESERVATION,
};

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useNft } from '../../../hooks/useNft';
import { NftInfoBarContainer } from '../../nft-price-container-common/styles';

export const NftInfoBarQueued = () => {
    const [peopleInQueue, setPeopleInQueue] = useState<number>(0);
    const router = useRouter();
    const nftId = router.query.id;
    const { nft } = useNft({ id: nftId, stats: true });

    useEffect(() => {
        if (nft && nft.data) {
            const totalQueued = Number(nft.data.stats?.totalQueued) || 0;
            setPeopleInQueue(totalQueued);
        }
    }, [nft]);

    if (peopleInQueue === 0) return null;

    if (peopleInQueue === 1) return null;

    return (
        <NftInfoBarContainer>
            <p>{peopleInQueue} people in queue</p>
        </NftInfoBarContainer>
    );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const EthIcon = () => {
    return (
        <FontAwesomeIcon
            icon={faEthereum as IconProp}
            className="cursor-pointer"
            style={{
                color: '#fff',
            }}
        />
    );
};

export default EthIcon;

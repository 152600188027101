import React, { useMemo, memo } from 'react';
import Countdown from 'react-countdown';
import {
    Container,
    PriceContainer,
    PrimaryPrice,
} from '../../nft-price-container-common/styles';
import { TimeUtil } from '../../../utils/time-converter';
import { upcomingTimeRendererProfilePage } from '../../../utils/datetime-renderer';

interface IUpComingContainerProps {
    auctionStartTime: string;
    shrink?: boolean;
    sticky?: boolean;
}

const UpComingContainerProfilePage = ({
    auctionStartTime,
    shrink,
    sticky,
}: IUpComingContainerProps) => {
    const formattedDate = useMemo(
        () => TimeUtil.toLocalTime(auctionStartTime),
        [auctionStartTime]
    );

    return (
        <>
            <Container
                status={'upcoming'}
                centered={true}
                shrink={shrink}
                sticky={sticky}
            >
                <PriceContainer shrink={shrink}>
                    <PrimaryPrice
                        className="w-100 text-center text-white"
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        <Countdown
                            key={formattedDate}
                            date={formattedDate}
                            renderer={upcomingTimeRendererProfilePage}
                            className="fw-bolder"
                        />
                    </PrimaryPrice>
                </PriceContainer>
            </Container>
        </>
    );
};

export default memo(UpComingContainerProfilePage);

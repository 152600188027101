export const statusColors = {
    'coming-soon': '#E54F4F',
    onsale: '#e54fc4',
    upcoming: '#E54F4F',
    queued: '#E54FC4',
    sold: '#747076',
    openforpurchase: '#E54FC4',
    selected: '#E54FC4',
    cancel: '#E54FC4',
};

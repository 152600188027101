import React, { useMemo, memo, useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import {
    Container,
    PriceContainer,
    SecondaryPrice,
    Button,
    PrimaryPrice,
} from '../../nft-price-container-common/styles';
import { TimeUtil } from '../../../utils/time-converter';
import { upcomingTimeRendererDetailPage } from '../../../utils/datetime-renderer';
import { useEthPrice } from '../../../hooks/useEthPrice';
import { EthIcon } from '../../nft-price-container-common/styles/icons';
import { AccountingFormats } from '../../../utils/accounting-formats';

interface IUpComingContainerProps {
    auctionStartTime: string;
    priceMin: string;
    priceMax: string;
    shrink?: boolean;
    sticky?: boolean;
    buttonLoading?: boolean;
}

const UpComingContainer = ({
    auctionStartTime,
    priceMin,
    priceMax,
    shrink,
    sticky,
}: IUpComingContainerProps) => {
    const [priceData, setPriceData] = useState(null);
    const { ethPrice } = useEthPrice();

    useEffect(() => {
        if (ethPrice && ethPrice.data) {
            const price = ethPrice.data;
            setPriceData({
                max: AccountingFormats.clean(priceMax) * price,
                min: AccountingFormats.clean(priceMin) * price,
            });
        }
    }, [ethPrice, ethPrice?.data]);

    const formattedDate = useMemo(
        () => TimeUtil.toLocalTime(auctionStartTime),
        [auctionStartTime]
    );
    return (
        <>
            <Container status={'upcoming'} shrink={shrink} sticky={sticky}>
                {priceMax != priceMin && (
                    <PriceContainer shrink={shrink}>
                        <SecondaryPrice>
                            Max: ${priceMax}{' '}
                            {priceData && priceData.max > 0 && (
                                <span className="font-16">
                                    ({priceData.max.toFixed(2)} <EthIcon />)
                                </span>
                            )}
                        </SecondaryPrice>
                        <SecondaryPrice>
                            Min: ${priceMin}{' '}
                            {priceData && priceData.min > 0 && (
                                <span className="font-16">
                                    ({priceData.min.toFixed(2)} <EthIcon />)
                                </span>
                            )}
                        </SecondaryPrice>
                    </PriceContainer>
                )}

                {priceMax == priceMin && (
                    <PriceContainer shrink={shrink}>
                        <PrimaryPrice>{`USD ${priceMax}`}</PrimaryPrice>
                        {priceData && priceData.max > 0 && (
                            <SecondaryPrice>
                                {priceData.max.toFixed(2)} <EthIcon />
                            </SecondaryPrice>
                        )}
                    </PriceContainer>
                )}

                {!shrink && (
                    <Button
                        status={'upcoming'}
                        disableHover={true}
                        style={{ flexDirection: 'column', cursor: 'auto' }}
                    >
                        <Countdown
                            key={formattedDate}
                            date={formattedDate}
                            renderer={upcomingTimeRendererDetailPage}
                            className="upcomingContainerPunchLine"
                        />
                    </Button>
                )}
            </Container>
        </>
    );
};

export default memo(UpComingContainer);

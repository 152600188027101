import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useNft } from '../../../hooks/useNft';
import { NftInfoBarContainer } from '../../nft-price-container-common/styles';

export const NftInfoBarUpcoming = ({ isMaster }) => {
    const [decayData, setDecayData] = useState(null);
    const router = useRouter();
    const nftId = router.query.id;
    const { nft } = useNft({ id: nftId });

    useEffect(() => {
        if (nft && nft.data) {
            const { decay } = nft.data;
            setDecayData((prevData) => ({
                ...prevData,
                decay: decay,
                shouldDecay: decay == 0 ? false : true,
            }));
        }
    }, [nft, nft?.data]);

    const decay = decayData?.decay;
    const copy = `Price drops every ${decay} minute${
        decay > 1 ? 's' : ''
    } once sale opens`;
    const shouldDecay = decayData?.shouldDecay;

    return (
        <NftInfoBarContainer>
            <p>{isMaster ? (shouldDecay ? copy : 'Fixed Price') : copy}</p>
        </NftInfoBarContainer>
    );
};

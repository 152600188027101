import Pusher from 'pusher-js';

let cached = null;

function bindPusherConnections(pusher) {
    pusher.connection.bind('connected', function () {
        console.log('Socket Connected');
    });

    pusher.connection.bind('error', function (err) {
        if (err?.error?.data?.code === 4004) {
            console.log('Sockets Over limit!');
        } else console.log('Pusher Error ', err);
    });

    pusher.connection.bind('state_change', function (states) {
        console.log(
            `Socket state changed from ${states.previous} to ${states.current} `
        );
    });
}

function getPusher(): Pusher {
    if (!cached) {
        console.log(
            'NEXT_PUBLIC_PUSHER_APP_KEY:',
            process.env.NEXT_PUBLIC_PUSHER_APP_KEY
        );
        console.log(
            'NEXT_PUBLIC_PUSHER_APP_CLUSTER:',
            process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER
        );
        cached = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY as string, {
            cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER as string,
        });
        bindPusherConnections(cached);
    }

    return cached;
}

function subscribe(channel: string) {
    try {
        return getPusher().subscribe(channel);
    } catch (error) {
        console.error('[Exception] subscribe pusher ', error);
    }
}

function unsubscribe(channel: string) {
    try {
        return getPusher().unsubscribe(channel);
    } catch (error) {
        console.error('[Exception] un-subscribe pusher ', error);
    }
}

function disconnect() {
    try {
        return getPusher().disconnect();
    } catch (error) {
        console.error('[Exception] disconnect pusher ', error);
    }
}

export const pusherUtil = {
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    disconnect: disconnect,
};

import styled from 'styled-components';

export const PunchLine = styled.p`
    font-size: 11px;
    margin: 0px;
    color: #444444;
`;

export const PaymentButtonMainCopy = styled.p`
    margin: 0;
`;

export const PaymentButtonSubCopy = styled.small`
    color: #e54fc4;
    font-size: 14px;
    margin-top: 4px;
`;

import {
    Modal,
    ModalLayout,
    ModalContent,
    ModalWhite,
    ModalWhiteRadius,
} from './index.styled';

import { useEffect, useRef } from 'react';
import useOnClickOutside from '../../../hooks/use-on-click-outside';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        active?: boolean;
    }
}

const CustomModalLayout = ({ handleModalClose, children, isModalOpen }) => {
    const ref = useRef();
    useOnClickOutside(ref, handleModalClose);
    const closeModalByEsc = (e) => {
        if (e.keyCode === 27) handleModalClose();
    };

    useEffect(() => {
        if (isModalOpen) window.addEventListener('keydown', closeModalByEsc);

        return () => {
            window.removeEventListener('keydown', closeModalByEsc);
        };
    }, [isModalOpen]);
    return (
        <>
            {children?.props?.style?.background === 'white' ? (
                <>
                    {children?.props?.style?.borderRadius === '8px' ? (
                        <ModalLayout active={isModalOpen}>
                            <ModalWhiteRadius ref={ref}>
                                <ModalContent>{children}</ModalContent>
                            </ModalWhiteRadius>
                        </ModalLayout>
                    ) : (
                        <ModalLayout active={isModalOpen}>
                            <ModalWhite ref={ref}>
                                <ModalContent>{children}</ModalContent>
                            </ModalWhite>
                        </ModalLayout>
                    )}
                </>
            ) : (
                <ModalLayout active={isModalOpen}>
                    <Modal ref={ref}>
                        <ModalContent>{children}</ModalContent>
                    </Modal>
                </ModalLayout>
            )}
        </>
    );
};
export default CustomModalLayout;

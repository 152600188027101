import styled from 'styled-components';
import { size } from '../../../../utils/device';

export const SecondaryPrice = styled.p`
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #fefefe;
    margin-bottom: 0px;

    @media (max-width: ${size.mobileM}) {
        font-size: 14px;
        line-height: 12px;
    }
`;

import styled from 'styled-components';
import { size } from '../../../../utils/device';
import { statusColors } from '../../utils/statusColors';
import { PunchLine } from '../punch-line';

interface IButtonProps {
    status: string;
    background?: string;
    color?: string;
    small?: boolean;
    disableHover?: boolean;
}

interface IPaymentButtonProps {
    readonly withFee?: boolean;
}

export const Button = styled.button<IButtonProps>`
    width: ${(props) => (props.small ? '200px' : '320px')};
    height: 54px;
    border: 1px solid #fff;
    border-radius: 30px;
    background: ${(props) => statusColors[props.background] || ' #fff'};
    color: ${(props) => props.color || '#000'};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .upcomingContainerPunchLine {
        color: #444;
    }

    ${(props) =>
        props.disableHover
            ? ''
            : `:hover {
        background: ${statusColors[props.status]};
        color: #fff;

        .upcomingContainerPunchLine {
            color: #fff;
        }

        ${PunchLine} {
            color: #fff;
        }

        small {
            color: #fff !important;
        }
    }`}

    @media (max-width: ${size.mobileM}) {
        font-size: 11px;
        height: 40px;
    }

    svg {
        @media (max-width: ${size.mobileM}) {
            height: 18px;
            width: 18px;
        }
    }
`;

export const PaymentButton = styled.button<IPaymentButtonProps>`
    width: 100%;
    height: 54px;
    border: 1px solid #fafafa;
    border-radius: 30px;
    background: transparent;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 0px 20px;

    @media (max-width: ${size.tablet}) {
        padding: 0px 10px;
    }

    :hover {
        background: white;
        color: black;
        border: 0px;
    }
`;

import styles from './three-dots-loader.module.css';

const ThreeDotsLoader = () => {
    return (
        <>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
        </>
    );
};

export default ThreeDotsLoader;

import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import fetcher from '../utils/fetch';

function useEthPrice() {
    const { data, error } = useSWR('/api/price-eth', (url) => fetcher(url), {
        refreshInterval: minutesToMilliseconds(5),
    });

    return {
        ethPrice: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export { useEthPrice };

import { IReservation } from '../../../types/reservation';
import { axiosClient } from '../../axios';

const RESERVE_NFT = async (setButtonLoading, userId, nftId) => {
    setButtonLoading(true);

    try {
        const response = await axiosClient.post('reservation/nft/reserve', {
            userId,
            nftId,
        });

        setButtonLoading(false);

        if (response.data.status) {
            return {
                status: response.data.status,
                reservationId: response.data.data.reservation._id,
                reservationStatus: response.data.data.reservation.status,
                lastOrderUrl: response.data.data.reservation.lastOrderUrl,
                orderService: response.data.data.reservation.orderService,
                orderPrice: response.data.data.reservation.orderPrice,
            };
        } else {
            return {
                status: false,
            };
        }
    } catch (error) {
        setButtonLoading(false);
        console.error('ERROR [RESERVE_NFT]', error);
    }
};

const GET_RESERVATION_STATUS = async (
    userId,
    nftId
): Promise<{
    status: boolean;
    statusMsg: string;
    data: IReservation;
}> => {
    try {
        const axiosRes = await axiosClient.post('reservation/nft/status', {
            userId,
            nftId,
        });

        if (axiosRes.status != 200)
            return {
                status: false,
                statusMsg: 'Api request failed',
                data: undefined,
            };

        const apiResponse = axiosRes.data;

        if (!apiResponse.status)
            return {
                status: false,
                statusMsg: 'Api response not success',
                data: undefined,
            };

        if (!apiResponse.data)
            return {
                status: true,
                statusMsg: 'No reservation data found.',
                data: undefined,
            };

        const reservationData = apiResponse.data;

        return {
            status: true,
            statusMsg: 'reservation data found.',
            data: {
                status: reservationData.status,
                id: reservationData._id,
                lastOrderUrl: reservationData.lastOrderUrl,
                orderService: reservationData?.orderService,
                expiryTime: reservationData?.expiresAt,
                orderPrice: reservationData.orderPrice,
            },
        };
    } catch (error) {
        console.error('ERROR [GET_RESERVATION_STATUS]', error);
        return {
            status: false,
            statusMsg: 'Failed to fetch reservation.',
            data: undefined,
        };
    }
};

const GO_TO_PAYMENT_GATEWAY = async (
    setButtonLoading,
    setShowLoader,
    router,
    lastOrderUrl,
    orderService = '',
    reservationId,
    userId,
    nftId,
    setReservationState
) => {
    console.log('payment gateway func called');

    if (orderService === 'bank-transfer') {
        setButtonLoading(true);

        const cancelRes = await CANCEL_RESERVATION(
            userId,
            nftId,
            reservationId,
            true
        );

        if (!cancelRes.status) {
            console.error(cancelRes);
            setButtonLoading(false);
            return {
                status: false,
                statusMsg: 'Failed to cancel reservation',
            };
        }
        setReservationState('canceled');
        setButtonLoading(false);
    } else {
        setButtonLoading(true);
        setShowLoader(true);
        router.push(lastOrderUrl);
    }
};

const CANCEL_RESERVATION = async (
    userId: string,
    nftId: string,
    reservationId: string,
    bankTransfer = false
): Promise<{ status: boolean; statusMsg: string }> => {
    try {
        let API_URL = 'reservation/nft/cancel';
        if (bankTransfer) {
            API_URL = 'reservation/nft/cancel-bank-transfer';
        }
        const axiosRes = await axiosClient.post(API_URL, {
            userId,
            nftId,
            reservationId,
        });

        if (axiosRes.status != 200)
            return {
                status: false,
                statusMsg: 'Api request failed',
            };

        const apiResponse = axiosRes.data;

        if (!apiResponse.status)
            return {
                status: false,
                statusMsg: 'Api response not success',
            };

        return {
            status: true,
            statusMsg: 'Reservation canceled.',
        };
    } catch (error) {
        console.error('ERROR [RESERVE_NFT]', error);
        return {
            status: false,
            statusMsg: 'Failed to cancel reservation.',
        };
    }
};

export const NftReservationUtil = {
    RESERVE_NFT,
    GET_RESERVATION_STATUS,
    GO_TO_PAYMENT_GATEWAY,
    CANCEL_RESERVATION,
};

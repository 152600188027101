import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import { FE_GET_OWNED_EDITIONS } from '../utils/api-urls-fe';
import fetcher from '../utils/fetch';

function useOwnedEditions(nftId) {
    const { data } = useSWR(
        FE_GET_OWNED_EDITIONS(nftId),
        (url) => fetcher(url),
        {
            refreshInterval: minutesToMilliseconds(1),
            revalidateOnFocus: true,
        }
    );

    return {
        ownedCount: data?.data?.count ?? 0,
    };
}

export { useOwnedEditions };

import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import {
    USER_SIGNUP_BACKDROP,
    LOGO_IMAGE_PATH,
} from '../../../utils/constants';
import { Modal, ModalContent, ModalLayout } from '../custom-modal/index.styled';
import { H5, OverLineGrey4, Subtitle3Grey3 } from '../../styled-typography';
import { useRouter } from 'next/router';
import { SignInOutButton } from '../../buttons/signInOutButton';
import { checkUserAgentInstagram, signInHandler } from '../../../utils/auth';
import { amplitudeUtil } from '../../../utils/amplitude/client';
import Link from 'next/link';
import GoogleLogo from '../../../public/images/logos/google.svg';
import AppleLogo from '../../../public/images/logos/apple.svg';
import styles from './styles.module.css';
import { fetcherPost } from '../../../utils/fetch';
import { useSnackbar } from 'react-simple-snackbar';
import { snackBarOptions } from '../../../utils/snackbar-options';

export const LoginModal = ({
    show,
    setShow,
    callbackPath = undefined,
    creatorData = undefined,
}) => {
    const modalRef = useRef();
    const router = useRouter();

    let baseUrl =
        process.env.NEXT_PUBLIC_APP_BASE_URL || window.location.origin;

    const callbackUrl = callbackPath ? baseUrl + callbackPath : router.asPath;

    const [openSnackbar] = useSnackbar(snackBarOptions);

    const [disableButtons, setDisableButton] = useState(false);
    const [googleSpinner, setGoogleSpinner] = useState(false);
    const [appleSpinner, setAppleSpinner] = useState(false);

    const [emailAddress, setEmailAddress] = useState('');
    const [disableSubButton, setDisableSubButton] = useState(true);

    const handleSignIn = (provider, setSpinner) => {
        amplitudeUtil.log('User Sign-in [Initiated]', {
            source: 'Sign-in Modal',
            provider: provider,
            url: router.pathname,
            returnUrl: callbackUrl,
        });

        if (provider === 'google') {
            const isUserAgentInstagram = checkUserAgentInstagram();
            if (isUserAgentInstagram) {
                return router.replace(`/detect-user-agent?r=${callbackUrl}`);
            }
        }

        signInHandler(provider, callbackUrl, setDisableButton, setSpinner);
    };

    const onSubscribe = async () => {
        setDisableSubButton(true);
        /** @todo move this to subscribe page */
        const response = await fetcherPost('api/subscribe-creator-nosession', {
            creatorId: creatorData._id,
            email: emailAddress,
            subscriptionLink: creatorData.subscriptionLink,
            creatorName: creatorData.name,
        });
        if (response.status) {
            setShow(false);
            openSnackbar(response.statusMsg, 3500);
            setEmailAddress('');
        } else {
            setShow(false);
            openSnackbar(response.statusMsg, 3500);
            setEmailAddress('');
        }
    };

    const validateEmail = () => {
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        setDisableSubButton(!validateEmail());
    }, [emailAddress]);

    return (
        <FullScreenModalLayout active={show}>
            <FullScreenModal ref={modalRef}>
                <FullScreenModalContent id="login-modal">
                    <div className="d-flex flex-row w-100">
                        <div className="d-none d-md-flex w-30">
                            <img
                                style={{ objectFit: 'cover' }}
                                src={USER_SIGNUP_BACKDROP}
                                width={'auto'}
                                height={'100%'}
                            />
                        </div>
                        <div className="d-flex flex-column w-100 h-100 bg-white ">
                            <div
                                onClick={() => setShow(false)}
                                className="d-flex m-3 px-2 m-md-5 px-md-5 font-24 fw-light color-black align-self-end cursor-pointer"
                            >
                                <Image
                                    id="close-login-modal"
                                    priority
                                    src="/assets/icon/close.svg"
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div className="mt-5 align-items-center">
                                <div
                                    style={{ maxWidth: '550px' }}
                                    className="mx-auto d-flex flex-column px-5"
                                >
                                    <div>
                                        {/* <img
                                            src={LOGO_IMAGE_PATH}
                                            height={88}
                                        /> */}
                                        <div className="my-3">
                                            <H5 className="color-black">
                                                Sign in / up
                                            </H5>
                                            {/* <SubTitle1 className="color-black m-0 mt-2 fw-300">
                                                Join thousands of superfans for
                                                a new class of experiences.
                                            </SubTitle1> */}
                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <SignInOutButton
                                            label={'Continue with Google'}
                                            disabled={disableButtons}
                                            onClick={() =>
                                                handleSignIn(
                                                    'google',
                                                    setGoogleSpinner
                                                )
                                            }
                                            spinner={googleSpinner}
                                            img={GoogleLogo.src}
                                        ></SignInOutButton>
                                    </div>
                                    <div>
                                        <SignInOutButton
                                            label={'Continue with Apple'}
                                            disabled={disableButtons}
                                            onClick={() =>
                                                handleSignIn(
                                                    'apple',
                                                    setAppleSpinner
                                                )
                                            }
                                            spinner={appleSpinner}
                                            img={AppleLogo.src}
                                        ></SignInOutButton>
                                    </div>
                                    {creatorData && (
                                        <>
                                            <OverLineGrey4
                                                style={{ fontSize: '1rem' }}
                                                className="my-4 align-self-center"
                                            >
                                                or
                                            </OverLineGrey4>
                                            <input
                                                className={`${styles.input} py-3 w-100`}
                                                name="email"
                                                id="email"
                                                placeholder="email address"
                                                onChange={(e) =>
                                                    setEmailAddress(
                                                        e.target.value
                                                    )
                                                }
                                                value={emailAddress}
                                            />
                                            <button
                                                id="subscribe-creator"
                                                className={`${styles.subscribe} mt-4`}
                                                disabled={disableSubButton}
                                                onClick={() => onSubscribe()}
                                            >
                                                Subscribe
                                            </button>
                                        </>
                                    )}
                                    <Subtitle3Grey3 className="mt-4 fw-300">
                                        By Selecting Continue with Google or
                                        Continue with Apple, I acknowledge that
                                        I have read and agree to SuperFandom's{' '}
                                        <Link href="/terms-of-service">
                                            Terms of Service
                                        </Link>{' '}
                                        and{' '}
                                        <Link href="/privacy-policy">
                                            Privacy Policy
                                        </Link>{' '}
                                        in their entirety
                                    </Subtitle3Grey3>
                                </div>
                            </div>
                        </div>
                    </div>
                </FullScreenModalContent>
            </FullScreenModal>
        </FullScreenModalLayout>
    );
};

const FullScreenModalLayout = styled(ModalLayout)`
    display: flex;

    margin: 0px;
    padding: 0px;

    border-radius: 0px;

    width: 100%;
    max-width: 100%;

    height: 100%;
    max-height: 100%;
`;

const FullScreenModal = styled(Modal)`
    display: flex;

    margin: 0px;
    padding: 0px;

    border-radius: 0px;

    width: 100%;
    max-width: 100%;

    height: 100%;
    max-height: 100%;
`;

const FullScreenModalContent = styled(ModalContent)`
    display: flex;

    margin: 0px;
    padding: 0px;

    border-radius: 0px;

    width: 100%;
    max-width: 100%;

    height: 100%;
    max-height: 100%;
`;

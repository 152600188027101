import React from 'react';
import styles from './styles.module.css';

interface ButtonProps {
    backgroundColor?: string;
    img?: string;
    label?: string;
    disabled?: boolean;
    spinner?: boolean;
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const SignInOutButton = ({
    img,
    backgroundColor = 'transparent',
    label = 'Button',
    disabled = false,
    spinner = false,
    ...props
}: ButtonProps) => {
    return (
        <button
            style={{ backgroundColor: backgroundColor }}
            type="button"
            className={styles.authenticationButton}
            disabled={disabled}
            {...props}
        >
            <div className={styles.imgLabel__container}>
                {img && (
                    <img
                        style={{ padding: '0 16px 0 0' }}
                        src={img}
                        alt="logo"
                    />
                )}
                {label}
            </div>
            <span
                style={{ display: spinner ? 'flex' : 'none' }}
                className={`${styles.spinner} spinner-border spinner-border-sm`}
                role="status"
                aria-hidden="true"
            ></span>
        </button>
    );
};

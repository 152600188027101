import ThreeDotsLoader from '../../loader/three-dots-loader';
import {
    Button,
    Container,
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';

const OnSaleContainerProfile = ({
    priceUsd,
    priceBtc,
    shrink,
    sticky,
    buttonLoading = false,
}) => {
    const NFT_STATES = {
        ONSALE: 'onsale',
        SOLD: 'sold',
    };

    return (
        <>
            <Container
                status={NFT_STATES.ONSALE}
                centered={buttonLoading}
                sticky={sticky}
                shrink={shrink}
            >
                {buttonLoading ? (
                    <ThreeDotsLoader />
                ) : (
                    <>
                        <PriceContainer shrink={shrink}>
                            <PrimaryPrice>{priceUsd}</PrimaryPrice>
                            <SecondaryPrice>
                                {priceBtc} <EthIcon />
                            </SecondaryPrice>
                        </PriceContainer>
                        {!shrink && (
                            <Button status={NFT_STATES.ONSALE}>{'BUY'}</Button>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default OnSaleContainerProfile;

import React from 'react';
import ThreeDotsLoader from '../../loader/three-dots-loader';
import {
    Container,
    PriceContainer,
    SecondaryPrice,
} from '../../nft-price-container-common/styles';

interface IComingSoonContainerProps {
    shrink?: boolean;
    sticky?: boolean;
    buttonLoading?: boolean;
}

const ComingSoonContainer = ({
    shrink,
    sticky,
    buttonLoading,
}: IComingSoonContainerProps) => {
    return (
        <Container
            status={'coming-soon'}
            centered={true}
            shrink={shrink}
            sticky={sticky}
        >
            {buttonLoading ? (
                <>
                    <ThreeDotsLoader />
                </>
            ) : (
                <PriceContainer shrink={shrink}>
                    <SecondaryPrice className="m-0 text-center w-100">
                        See Details
                    </SecondaryPrice>
                </PriceContainer>
            )}
        </Container>
    );
};

export default ComingSoonContainer;

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from './styles.module.css';
import { CreatorInfo } from '../creator-info';
import {
    NFT_CARD_MEDIA_HEIGHT,
    NFT_CARD_MEDIA_WIDTH,
} from '../../utils/constants';
import ShimmerURL from '../../utils/images-shimmer';
import {
    Caption,
    H6,
    CardWrapper as Wrapper,
    Stack1,
    Stack2,
} from '../styled-typography';
import PriceContainerProfile from '../nft-price-container-profile';
import NftMeta from '../NFTDetail/nftMeta';
import { NFTCardProps } from '../../interfaces/NftCard';
import { useNft } from '../../hooks/useNft';

export const NFTCard = ({
    _id,
    href,
    mediaUrl = '/url',
    creator = { userName: 'creatorUsername' },
    title = 'Title',
    description,
    category = 'category',
    auctionStartTime,
    gradientBorder = false,
    showCreatorInfo = false,
    narrowCardContainer = false,
}: NFTCardProps) => {
    const [nftData, setNftData] = useState(null);
    const { nft, isLoading: loading } = useNft({ id: _id });

    useEffect(() => {
        if (nft && nft.data) {
            const {
                isMasterEdition,
                currentEdition,
                totalEditions,
                editionNft,
                stats,
                status,
            } = nft.data;
            setNftData({
                isMaster: isMasterEdition,
                currentEdition: currentEdition,
                totalEditions: totalEditions,
                isEdition: editionNft,
                stats: stats,
                status: status,
            });
        }
    }, [nft, nft?.data]);

    const isMaster = nftData?.isMaster || false;
    const isEdition = nftData?.isEdition || false;
    const editionsLeft =
        nftData?.totalEditions - (nftData?.stats?.totalSold ?? 0);

    return (
        <Wrapper className="mt-2 mx-2">
            <div
                style={{
                    position: 'relative',
                    zIndex: 3,
                    boxShadow:
                        '0px 3px 5px rgba(0, 0, 0, 0.25), 0px 4px 11px rgba(0, 0, 0, 0.1)',
                    borderRadius: '25px',
                }}
            >
                <Link href={href ? href : `/nft/${_id}`}>
                    <div
                        className={`${
                            gradientBorder
                                ? styles.gradientBorder
                                : styles.border
                        }  ${
                            narrowCardContainer
                                ? styles.narrowCardContainer
                                : styles.cardContainer
                        } cursor-pointer`}
                    >
                        <div
                            className={`d-flex justify-content-center ${styles.mediaContainer}`}
                        >
                            {mediaUrl && (
                                <Image
                                    className={styles.mediaImage}
                                    src={mediaUrl}
                                    width={NFT_CARD_MEDIA_WIDTH}
                                    height={NFT_CARD_MEDIA_HEIGHT}
                                    blurDataURL={ShimmerURL(
                                        NFT_CARD_MEDIA_WIDTH,
                                        NFT_CARD_MEDIA_HEIGHT
                                    )}
                                    placeholder="blur"
                                />
                            )}
                            {showCreatorInfo && (
                                <Link href={`/${creator?.userName}`}>
                                    <div
                                        className={` d-flex justify-content-center ${styles.creatorInfoContainer}`}
                                    >
                                        <CreatorInfo creator={creator} />
                                    </div>
                                </Link>
                            )}
                        </div>
                        <div className={styles.innerContainer}>
                            <div className={styles.titleWrapper}>
                                <H6
                                    className={`mt-5 ${styles.title}`}
                                    style={{
                                        height: Boolean(
                                            nftData?.totalEditions > 1 ||
                                                isEdition
                                        )
                                            ? '22px'
                                            : '44px',
                                    }}
                                >
                                    {title}
                                </H6>
                            </div>
                            {isMaster &&
                                nftData &&
                                Boolean(nftData.totalEditions > 1) &&
                                nftData.status != 'sold' && (
                                    <p className={`${styles.editionCopy}`}>
                                        {editionsLeft}/{nftData.totalEditions}{' '}
                                        Edition
                                        {nftData.totalEditions > 1 ? 's' : ''}
                                    </p>
                                )}

                            {isMaster &&
                                nftData &&
                                Boolean(nftData.totalEditions > 1) &&
                                nftData.status === 'sold' && (
                                    <p className={`${styles.editionCopy}`}>
                                        {nftData.totalEditions} Edition
                                        {nftData.totalEditions > 1 ? 's' : ''}
                                    </p>
                                )}

                            {isEdition &&
                                nftData &&
                                Boolean(nftData.currentEdition == 0) && (
                                    <p className={`${styles.editionCopy}`}>
                                        Edition: Minting...
                                    </p>
                                )}

                            {isEdition &&
                                nftData &&
                                Boolean(nftData.currentEdition > 0) && (
                                    <p className={`${styles.editionCopy}`}>
                                        Edition: {nftData.currentEdition}/
                                        {nftData.totalEditions}
                                    </p>
                                )}

                            <Caption className={styles.description}>
                                {description}
                            </Caption>
                            <NftMeta
                                id={_id}
                                title={title}
                                category={{ title: category }}
                                small={narrowCardContainer}
                                isMaster={isMaster}
                            />
                            <PriceContainerProfile
                                nftId={_id}
                                shrink={true}
                                sticky={false}
                            />
                        </div>
                    </div>
                </Link>
            </div>

            {isMaster && (
                <>
                    <Stack1 />
                    <Stack2 />
                </>
            )}
        </Wrapper>
    );
};

import styled from 'styled-components';

interface IPriceContainerProps {
    shrink?: boolean;
}

export const PriceContainer = styled.div<IPriceContainerProps>`
    width: ${(props) => (props.shrink ? '140px' : '330px')};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
`;

import React from 'react';

import {
    Button,
    Container,
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';

interface Props {
    status: string;
    priceUsd: number;
    priceBtc: number;
    handleOnClickCrypto: () => void;
    buttonLoading: boolean;
    sticky: boolean;
    orderService: string;
}

const AwaitingPurchaseContainer = ({
    status,
    priceUsd,
    priceBtc,
    handleOnClickCrypto,
    sticky,
    orderService,
}: Props) => {
    const provider =
        orderService === 'coinbase'
            ? 'Continue with crypto'
            : orderService === 'checkout'
            ? 'Continue with cash'
            : orderService === 'bank-transfer'
            ? 'Cancel payment'
            : orderService === 'sendwyre'
            ? 'Continue with cash'
            : '';

    return (
        <>
            <Container status={status} sticky={sticky}>
                <PriceContainer>
                    <PrimaryPrice>{priceUsd}</PrimaryPrice>
                    <SecondaryPrice>
                        {priceBtc} <EthIcon />
                    </SecondaryPrice>
                </PriceContainer>

                <Button status={status} onClick={handleOnClickCrypto}>
                    <p className="m-0">{provider}</p>
                </Button>
            </Container>
        </>
    );
};

export default AwaitingPurchaseContainer;

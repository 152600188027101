import { PunchLine } from '../components/nft-price-container-common/styles';

export const renderer = ({ days, hours, minutes, seconds }) => {
    return (
        <span>
            {days}d:{hours}h:{minutes}m:{seconds}s
        </span>
    );
};

export const upcomingTimeRendererDetailPage = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) {
        return <span>Opening for sale...</span>;
    }

    return (
        <>
            <PunchLine>Open for sale in:</PunchLine>
            <span>
                {days > 0 && `${days}d:`}
                {hours > 0 && `${hours}h:`}
                {minutes > 0 && `${minutes}m:`}
                {minutes >= 0 && `${seconds}s`}
            </span>
        </>
    );
};

export const upcomingTimeRendererProfilePage = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) {
        return (
            <span className="fw-normal" style={{ fontSize: '15px' }}>
                Opening for sale...
            </span>
        );
    }

    return (
        <>
            <PunchLine className="text-white fw-normal">
                Open for sale in:
            </PunchLine>
            <span>
                {days > 0 && `${days}d:`}
                {hours > 0 && `${hours}h:`}
                {minutes > 0 && `${minutes}m:`}
                {minutes >= 0 && `${seconds}s`}
            </span>
        </>
    );
};

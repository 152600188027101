import React from 'react';
import { PWhite } from '../../styled-typography';
import CustomModal from '../custom-modal';
import styles from './styles.module.css';

const ConfirmationModal = ({
    showModal,
    onClickConfirm,
    onClickClose,
}: {
    showModal: boolean;
    onClickConfirm: React.MouseEventHandler<HTMLButtonElement>;
    onClickClose: React.MouseEventHandler<HTMLButtonElement>;
}) => {
    return (
        <CustomModal isModalOpen={showModal} handleModalClose={onClickClose}>
            <div className="d-flex justify-content-center align-items-center">
                <div className={styles.modalContainer}>
                    <div className={styles.confirmText}>
                        <PWhite className="mb-2">
                            Are you sure you want to cancel?
                        </PWhite>
                    </div>
                    <div className="d-flex flex-column align-content-center">
                        <button
                            onClick={onClickConfirm}
                            className={styles.yesBtn}
                        >
                            Yes
                        </button>
                        <button onClick={onClickClose} className={styles.noBtn}>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default ConfirmationModal;

import styled from 'styled-components';
import { size } from '../../../../utils/device';

export const NftInfoBarContainer = styled.div`
    height: 40px;
    background: #fff;
    width: 100%;
    border-bottom: 0px;
    display: grid;
    place-items: center;
    color: #000;

    p {
        margin: 0;

        a {
            text-decoration: underline;
            color: #000 !important;
        }
    }

    @media (max-width: ${size.tablet}) {
        border-radius: 0px;
        position: fixed !important;
        bottom: 72px;
        right 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    }
`;

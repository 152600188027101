import React from 'react';
import {
    Container,
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
    Button,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';

const ReservedContainer = ({
    status,
    priceUsd,
    priceBtc,
    message,
    sticky,
    showCancel = false,
    onClickCancel = () => {},
}) => {
    return (
        <>
            <Container status={status} sticky={sticky}>
                <PriceContainer>
                    <PrimaryPrice>{priceUsd}</PrimaryPrice>
                    <SecondaryPrice>
                        {priceBtc} <EthIcon />
                    </SecondaryPrice>
                </PriceContainer>
                <Button status={status} background={status} color={'white'}>
                    {message}
                </Button>

                {showCancel && (
                    <Button
                        onClick={onClickCancel}
                        className="m-1"
                        status={'cancel'}
                    >
                        Cancel
                    </Button>
                )}
            </Container>
        </>
    );
};

export default ReservedContainer;

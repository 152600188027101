import { signIn } from 'next-auth/react';

export const checkUserAgentInstagram = () => {
    const ua = navigator.userAgent || navigator.vendor;
    console.log('UserAgent', ua);
    const isInstagram = ua.indexOf('Instagram') > -1 ? true : false;
    console.log('IsInstagram', isInstagram);
    return isInstagram;
};

const handleAppleSignIn = (callbackUrl) => {
    localStorage.setItem(
        'AUTH_TRACK',
        JSON.stringify({
            provider: 'apple',
            callbackUrl,
        })
    );

    signIn('apple', {
        callbackUrl: callbackUrl,
    });
};

export const signInHandler = (
    provider: string,
    callbackUrl: string,
    setDisableButton,
    setSpinner
) => {
    setDisableButton(true);
    setSpinner(true);
    if (provider === 'google') {
        return signIn(provider, {
            callbackUrl: callbackUrl,
        });
    }
    if (provider === 'apple') {
        return handleAppleSignIn(callbackUrl);
    }
};

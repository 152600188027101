import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import {
    Button,
    Container,
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';
import { Props } from '../../../types/purchase.d';

const DynamicPaymentMethodsModal = dynamic(
    () => import('../../modals/payment-methods-modal'),
    {
        ssr: false,
    }
);

const OpenForPurchaseContainer = (props) => {
    const {
        status,
        priceUsd,
        priceBtc,
        handleOnClickCrypto,
        handleOnClickFiat,
        handleOnClickSendwyre,
        sticky,
        bankPayment,
        creator,
        setReservedState,
        setReservation,
        reservation,
    }: Props = props;

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <>
            <Container status={status} sticky={sticky}>
                <PriceContainer>
                    <PrimaryPrice>{priceUsd}</PrimaryPrice>
                    <SecondaryPrice>
                        {priceBtc} <EthIcon />
                    </SecondaryPrice>
                </PriceContainer>

                <Button status={status} onClick={() => setModalOpen(true)}>
                    Select payment method
                </Button>
            </Container>
            <DynamicPaymentMethodsModal
                isModalOpen={modalOpen}
                setIsModalOpen={setModalOpen}
                handleOnClickCrypto={handleOnClickCrypto}
                handleOnClickFiat={handleOnClickFiat}
                handleOnClickSendwyre={handleOnClickSendwyre}
                bankPayment={bankPayment}
                priceUsd={priceUsd}
                creator={creator}
                setReservedState={setReservedState}
                setReservation={setReservation}
                reservation={reservation}
            />
            ,
        </>
    );
};

export default OpenForPurchaseContainer;

function format(x) {
    if (!x) return x;

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function clean(x) {
    if (!x) return x;

    return Number(x.replace(/,/g, ''));
}

function purgeCurrencyUnit(x) {
    if (!x) return x;

    const purged = x.replace('USD', '');
    return Number(purged.trim());
}

export const AccountingFormats = {
    format,
    clean,
    purge: purgeCurrencyUnit,
};

import {
    Button,
    Container,
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';

const BuyContainer = ({
    status,
    sticky,
    updatedUsdPrice,
    priceBtc,
    handleOnClick,
}) => {
    return (
        <Container status={status} sticky={sticky}>
            <PriceContainer>
                <PrimaryPrice>{updatedUsdPrice}</PrimaryPrice>
                <SecondaryPrice>
                    {priceBtc} <EthIcon />
                </SecondaryPrice>
            </PriceContainer>
            <Button status={status} onClick={handleOnClick}>
                {'BUY'}
            </Button>
        </Container>
    );
};

export default BuyContainer;

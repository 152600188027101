import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import { FE_GET_RESERVATION_STATUS } from '../utils/api-urls-fe';
import fetcher from '../utils/fetch';

function useReservation(id: string, forced: boolean = false) {
    if (!id) {
        return {
            reservation: null,
            isLoading: false,
            isError: false,
        };
    }

    const { data, error } = useSWR(
        FE_GET_RESERVATION_STATUS(id, forced),
        (url) => fetcher(url),
        {
            refreshInterval: 30000, //10s
        }
    );

    return {
        reservation: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export default useReservation;

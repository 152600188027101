export const FE_GET_RESERVATION_STATUS = (
    id: string,
    forced: boolean = false
) => `/api/nfts/${id}/reservation/status?forced=${forced}`;

export const FE_GET_NFT = (id: string, stats = false) =>
    `/api/nfts/${id}?stats=${stats}`;

export const FE_GET_OWNED_EDITIONS = (id: string) =>
    `/api/nfts/${id}/editions/owned`;

// ====================== BUY NFT ================================ //

// Front-End Endpoints - NFT Edition
export const FE_POST_BUY_EDITION_NFT_WITH_FIAT = `reservation/nft-edition/buy/fiat`;

export const FE_POST_BUY_EDITION_NFT_WITH_CRYPTO = `reservation/nft-edition/buy/crypto`;

export const FE_POST_BUY_EDITION_NFT_WITH_SENDWYRE = `reservation/nft-edition/buy/sendwyre`;

export const FE_POST_BUY_EDITION_NFT_VIA_BANK = `reservation/nft-edition/buy/bank`;

// Front-End Endpoints - NFT
export const FE_POST_BUY_NFT_WITH_FIAT = `reservation/nft/buy/fiat`;

export const FE_POST_BUY_NFT_WITH_CRYPTO = `reservation/nft/buy/crypto`;

export const FE_POST_BUY_NFT_WITH_SENDWYRE = `reservation/nft/buy/sendwyre`;

export const FE_POST_BUY_NFT_VIA_BANK = `reservation/nft/buy/bank`;

// profile

export const FE_GET_USER_PROFILE = (username: string) =>
    `/api/users/${username}/profile`;

export const FE_GET_USER_PROFILE_OFFERS = (username: string, limit = 10) =>
    `/api/users/${username}/offers?limit=${limit}`;

export const FE_GET_USER_PROFILE_COLLECTION = (username: string) =>
    `/api/users/${username}/collection`;

export const FE_GET_USER_DISCORD_BOT_DATA = (username: string) =>
    `/api/users/${username}/discord-bot`;

export const FE_POST_CHECK_LINK_DISCORD = `/api/link/discord/check-links`;

// Front-End Endpoints - Account
export const FE_POST_DISCORD_TOKEN = () => `/api/link/discord`;

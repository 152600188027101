import React, { useState, useEffect } from 'react';
import { useNft } from '../../hooks/useNft';
import ThreeDotsLoader from '../loader/three-dots-loader';
import {
    ComingSoonContainer,
    SoldContainer,
    OnSaleContainerProfile,
    UpcomingContainerProfilePage,
} from '../nft-price-containers';
import { Container } from '../nft-price-container-common/styles';
import { AccountingFormats } from '../../utils/accounting-formats';

const PriceContainerProfile = ({ nftId, shrink, sticky }) => {
    const { nft, isLoading: loading } = useNft({ id: nftId });
    const [nftData, setNftData] = useState(null);

    useEffect(() => {
        if (nft && nft.data) {
            const { status, primaryPrice, secondaryPrice, auctionStartTime } =
                nft.data;
            setNftData({
                status: status,
                price: {
                    primary: AccountingFormats.format(primaryPrice),
                    secondary: secondaryPrice,
                },
                auctionTime: auctionStartTime,
            });
        }
    }, [nft]);

    const nftStatus = nftData?.status;

    if (loading)
        return (
            <Container
                status={'coming-soon'}
                centered={true}
                shrink={shrink}
                sticky={sticky}
            >
                <ThreeDotsLoader />
            </Container>
        );

    return (
        <div style={{ marginTop: '20px' }}>
            {nftStatus == 'coming-soon' && (
                <ComingSoonContainer shrink={shrink} sticky={sticky} />
            )}
            {nftStatus == 'upcoming' && (
                <UpcomingContainerProfilePage
                    auctionStartTime={nftData.auctionTime}
                    shrink={shrink}
                    sticky={sticky}
                />
            )}
            {nftStatus == 'onsale' && (
                <OnSaleContainerProfile
                    priceUsd={nftData.price.primary}
                    priceBtc={nftData.price.secondary}
                    shrink={shrink}
                    sticky={sticky}
                />
            )}
            {nftStatus == 'sold' && (
                <SoldContainer
                    priceUsd={nftData.price.primary}
                    priceBtc={nftData.price.secondary}
                    shrink={shrink}
                    sticky={sticky}
                />
            )}
        </div>
    );
};

export default PriceContainerProfile;

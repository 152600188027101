import { useSession } from 'next-auth/react';
import React from 'react';
import {
    PriceContainer,
    PrimaryPrice,
    SecondaryPrice,
    Container,
    Button,
} from '../../nft-price-container-common/styles';
import { EthIcon } from '../../nft-price-container-common/styles/icons';

interface ISoldContainerProps {
    priceUsd: string;
    priceBtc: string;
    shrink?: boolean;
    sticky?: boolean;
    owner?: any;
}

const SoldContainer = ({
    priceUsd,
    priceBtc,
    shrink,
    sticky,
    owner,
}: ISoldContainerProps) => {
    const { data: session, status } = useSession();

    const isOwner =
        status === 'authenticated' &&
        session &&
        owner &&
        session.username === owner.username;

    return (
        <>
            <Container
                status={'sold'}
                shrink={shrink}
                sticky={sticky}
                centered={!shrink}
                className={isOwner ? 'justify-content-start' : null}
            >
                <PriceContainer shrink={shrink}>
                    <PrimaryPrice>{priceUsd}</PrimaryPrice>
                    <SecondaryPrice>
                        {priceBtc} <EthIcon />
                    </SecondaryPrice>
                </PriceContainer>

                {!shrink && !isOwner && (
                    <Button
                        small={shrink}
                        status={'sold'}
                        background={'sold'}
                        color={'white'}
                    >
                        {'DROP ENDED'}
                    </Button>
                )}
            </Container>
        </>
    );
};

export default React.memo(SoldContainer);

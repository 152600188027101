import styled from 'styled-components';

interface IContainerProps {
    isVertical: boolean;
}

interface ICategoryButtonProps {
    small: boolean;
    isMaster: boolean;
}

const Container = styled.div<IContainerProps>`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
    justify-content: ${(props) => (props.isVertical ? '' : 'space-between')};
    align-items: center;
`;

const CategoryButton = styled.button<ICategoryButtonProps>`
    background-color: transparent;
    border: ${(props) =>
        props.isMaster ? '1px solid #E54FC4' : '1px solid #ffe074'};
    border-radius: 5px;
    padding: ${(props) => (props.small ? '4px' : '6px 8px')};
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: ${(props) =>
        props.small ? '10px !important' : '12px !important'};
    line-height: 14px !important;
    letter-spacing: 0.5px !important;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        color: ${(props) => (props.isMaster ? '#E54FC4' : '#565159')};
    }
`;

const ShareButton = styled.button`
    background-color: transparent;
    border: 0px;
    padding: 0px;
`;

const ImageContainer = styled.div`
    width: 297px;
    height: 297px;
    background: white;
    border: 1px solid white;
    box-shadow: 0px 6px 24px 17px rgba(0, 0, 0, 0.08);
    border-radius: 24px;

    :hover {
        opacity: 0.95;
    }
`;
export { Container, CategoryButton, ShareButton, ImageContainer };

import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useOwnedEditions } from '../../../hooks/useOwnedEditions';
import { NftInfoBarContainer } from '../../nft-price-container-common/styles';

export const NftInfoBarOwnedEditions = () => {
    const { data: session } = useSession();
    const router = useRouter();
    const nftId = router.query.id;
    let { ownedCount } = useOwnedEditions(nftId);

    const term = ownedCount > 1 ? 'editions' : 'edition';
    const count = ownedCount > 10 ? '10+' : ownedCount;
    const editions = count + ' ' + term;

    if (ownedCount === 0) return null;

    return (
        <>
            <NftInfoBarContainer>
                <p>
                    You already own{' '}
                    <Link href={`/${session?.username}`}>{editions}</Link>
                </p>
            </NftInfoBarContainer>
        </>
    );
};

import styled from 'styled-components';
import { statusColors } from '../../utils/statusColors';
import { size } from '../../../../utils/device';

interface IContainerProps {
    status: string;
    centered?: boolean;
    shrink?: boolean;
    sticky?: boolean;
    isMaster?: boolean;
    isLoading?: boolean;
}

export const Container = styled.div<IContainerProps>`
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    width: ${(props) => (props.shrink ? '100%' : '608px')};
    height: 72px;
    background: ${(props) => statusColors[props.status]};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.centered ? 'center' : 'start')};
    position: relative !important;

    ${(props) =>
        props.sticky &&
        `
        @media (max-width: ${size.tablet}) {
            position: fixed !important;
            bottom: 0;
            right 0;
            left: 0;
            width: 100%;
            z-index: 9999;
            border-radius: 0px;
            margin-top: 0px;
            padding: 12px 10px;
        `}
    }
    
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
